var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '500',"persistent":""},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"expande-horizontal centraliza"},[_c('v-card',{staticClass:"expande-horizontal column pa-3",staticStyle:{"flex-wrap":"wrap","border-radius":"12px"},attrs:{"dark":"","color":"#e5e5e5"}},[(_vm.getLoggedUser.accession_paid)?_c('span',{staticClass:"mb-3 fonte black--text",style:("font-size: 12px;")},[_vm._v(" Adesão paga ")]):_c('span',{staticClass:"mb-3 fonte black--text",style:("font-size: 12px;")},[_vm._v(" 1º Passo: Adesão ")]),(_vm.getLoggedUser.accession_paid)?_c('div',{class:{
            'expande-horizontal wrap centraliza':
              _vm.$vuetify.breakpoint.smAndDown
          }},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"expande-horizontal centraliza"},[_c('v-avatar',{attrs:{"color":"green"}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)]),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('div',{staticClass:"expande-horizontal centraliza column"},[_c('span',{staticClass:"py-4 fonte-bold black--text",staticStyle:{"font-size":"24pt"}},[_vm._v(" Pagamento Recebido ")]),_c('span',{staticClass:"fonte grey--text mb-2 text-center",staticStyle:{"font-size":"12pt"}},[_vm._v(" Agora você já pode utilizar todas as ferramentas disponíveis que preparamos pra você! ")]),(!_vm.getLoggedUser.accession_paid)?_c('v-btn',{staticClass:"mb-2",attrs:{"depressed":""}},[_vm._v("Já Paguei")]):_vm._e(),(_vm.getLoggedUser.accession_paid)?_c('v-btn',{staticClass:"mb-2",attrs:{"depressed":""},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Vamos lá")]):_vm._e()],1)])],1):_c('div',{class:{
            'expande-horizontal wrap centraliza':
              _vm.$vuetify.breakpoint.smAndDown
          }},[_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('div',{staticClass:"expande-horizontal centraliza column"},[_c('span',{staticClass:"fonte-bold black--text",staticStyle:{"font-size":"24pt"}},[_vm._v(" Boleto de adesão ")]),_c('span',{staticClass:"fonte grey--text mb-2",staticStyle:{"font-size":"12pt"}},[_vm._v(" Visualize ou faça o download abaixo ")]),_c('v-btn',{staticClass:"mb-2",attrs:{"depressed":""},on:{"click":_vm.verifyAccessionPaid}},[_vm._v("Já Paguei")])],1)]),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('div',{staticClass:"expande-horizontal column mb-2 pb-1 pt-0",staticStyle:{"width":"100%"}},[_c('iframe',{attrs:{"height":"500","src":_vm.bankSlipPending && _vm.bankSlipPending.external_data
                    ? _vm.bankSlipPending.external_data.bankSlipUrl
                    : false,"frameborder":"0"}}),(false)?_c('button',{staticClass:"btn2 ml-1 mt-2",staticStyle:{"background-color":"#333","border-radius":"6px","min-width":"40px","min-height":"40px","max-height":"40px"},attrs:{"icon":"","data-clipboard-target":"#foo"},on:{"click":function($event){return _vm.createGlobalMessage({
                    type: 'success',
                    message: 'Número do boleto copiado com sucesso!',
                    timeout: 3000
                  })}}},[_c('span',{staticClass:"fonte-bold white--text"},[_vm._v(" Clique baixar o boleto ")]),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-download")])],1):_vm._e()])])],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }