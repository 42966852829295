<template>
  <v-card
    outlined
    color="#f2f2f2"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
      <span class="mb-3 fonte black--text text-center" :style="`font-size: 12px;`">
        Baixe nosso APP da Conta Digital e abra sua conta Revolution
      </span>

      <div style="width: 100%" class="expande-horizontal centraliza mb-2 pb-1 pt-0">
        <button
          class="elevation-2 ml-2"
          icon
          @click="goAndroid"
          style="background-color: #333;border-radius: 50%;min-width: 40px; min-height: 40px; max-width: 40px; max-height: 40px;"
        >
          <v-icon color="green">mdi-android</v-icon>
        </button>
        <button
          class="elevation-2 ml-2"
          icon
          @click="goIos"
          style="background-color: #333;border-radius: 50%;min-width: 40px; min-height: 40px; max-width: 40px; max-height: 40px;"
        >
          <v-icon color="#f2f2f2">mdi-apple</v-icon>
        </button>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      myLink: ""
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    goToRegister() {
      window.open(
        "https://acessoaconta.com.br/Cadastro/scn/PessoaJuridica/eyJjb2RfcHJvZHV0byI6MjUxLCJ0aXBvIjoicGoifQ==",
        "_blank"
      );
    },
    goAndroid() {
      window.open(
        'https://play.google.com/store/apps/details?id=br.com.scnrevolution.app',
        '_blank'
      )
    },
    goIos() {
      window.open(
        'https://apps.apple.com/br/app/scn-revolution/id1444615089',
        '_blank'
      )
    },
  },
};
</script>
