<template>
  <v-card
    color="#333"
    dark
    :height="$vuetify.breakpoint.smAndDown ? '180' : '240'"
    class="expande-horizontal elevation-0"
  >
    <div class="expande-horizontal" style="max-height: 240px;">
      <canvas
        :height="$vuetify.breakpoint.smAndDown ? '155' : '110'"
        id="myChart"
      ></canvas>
    </div>
  </v-card>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  data() {
    return {};
  },
  methods: {
    montaChart() {
      var ctx = document.getElementById("myChart").getContext("2d");
      const config = {
        type: "line",
        data: {
          labels: ["1", "2", "3", "4", "5", "6", "7"],
          datasets: [
            {
              label: "Rendimento de Ativos",
              backgroundColor: ["#4CAF50"],
              borderColor: ["#4CAF50"],
              data: [12, 19, 3, 5, 50, 30, 70, 100, 120, 130, 15], // data: this.dados
              fontColor: "#4CAF50",
              color: "#4CAF50"
            },
            {
              label: "Bônus de Indicação",
              backgroundColor: ["#CDDC39"],
              borderColor: ["#CDDC39"],
              data: [0, 19, 6, 87, 56, 23, 35, 1, 120, 10, 15], // data: this.dados
              fontColor: "#CDDC39",
              color: "#CDDC39"
            }
          ]
        }
      };

      const options = {
        responsive: false,
        plugins: {
          legend: {
            position: "top"
          },
          title: {
            display: true,
            text: "Vendas por período"
          }
        }
      };

      new Chart(ctx, config, {});
    }
  },
  mounted() {
    this.montaChart();
  }
};
</script>
