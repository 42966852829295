var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"expande-horizontal column pa-3",staticStyle:{"flex-wrap":"wrap","border-radius":"12px"},attrs:{"dark":"","outlined":"","color":"#f2f2f2"}},[_c('span',{staticClass:"mb-3 fonte black--text",style:("font-size: 12px;")},[_vm._v(" Meu cartão ")]),_c('div',{class:{
      'expande-horizontal centraliza': _vm.$vuetify.breakpoint.smAndDown
    }},[_c('div',{staticClass:"card-base",class:{
        'personal-card': _vm.graduation == 'Personal',
        'gold-card force-black-text': _vm.graduation == 'Gold',
        'platinum-card force-black-text': _vm.graduation == 'Platinum',
        'black-card': _vm.graduation == 'Black',
        'black--text': [''].includes(_vm.graduation),
        'white--text': ['Personal', 'Gold', 'Platinum ', 'Black'].includes(
          _vm.graduation
        )
      }},[_c('div',{staticClass:"expande-horizontal",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"expande-horizontal column"},[_c('span',{staticClass:"fonte-bold mb-0",style:("font-size: 16px;")},[_vm._v(_vm._s(_vm.graduation || "Sem classificação"))]),_c('span',{staticClass:"fonteMini",staticStyle:{"position":"relative","margin-top":"-8px"}},[_vm._v("nível")])]),_c('div',[(_vm.graduation)?_c('v-icon',{staticClass:"mr-0",attrs:{"size":"31","color":"white"}},[_vm._v("mdi-integrated-circuit-chip")]):_c('span',{staticClass:"fonte-bold black--text"},[_vm._v("SCN")])],1)]),_c('v-spacer'),_c('div',{staticClass:"column expande-horizontal",staticStyle:{"align-items":"flex-end"}},[_c('span',{staticClass:"fonte mt-3"},[_vm._v(" 0000 0000 0000 "+_vm._s(_vm.getIdCard))]),_c('span',{staticClass:"fonte fonte-bold",staticStyle:{"font-size":"13pt"}},[_vm._v(_vm._s(_vm.firstName))]),(!_vm.graduation)?_c('span',{staticClass:"fonteTitulo",staticStyle:{"font-size":"9pt"}},[_vm._v(_vm._s(_vm.getUnilevels.level_1.length)+" de 6 até o nível Personal")]):(_vm.graduation == 'Personal')?_c('span',{staticClass:"fonteTitulo",staticStyle:{"font-size":"9pt"}},[_vm._v(_vm._s(_vm.getUnilevels.level_2.length)+" de 36 até o nível Gold")]):(_vm.graduation == 'Gold')?_c('span',{staticClass:"fonteTitulo",staticStyle:{"font-size":"9pt"}},[_vm._v(_vm._s(_vm.getUnilevels.level_3.length)+" de 216 até o nível Platinum")]):(_vm.graduation == 'Platinum')?_c('span',{staticClass:"fonteTitulo",staticStyle:{"font-size":"9pt"}},[_vm._v(_vm._s(_vm.getUnilevels.level_4.length)+" de 1296 até o nível Black")]):_vm._e()]),_c('div',{staticClass:"logo-card-scn"},[_c('img',{staticClass:"img-logo",staticStyle:{"opacity":"0.4"},attrs:{"height":"220","src":"logo_mini.png","alt":"logo scn"}})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }