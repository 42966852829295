<template>
  <v-card
    dark
    height="140"
    outlined
    color="#f2f2f2"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
      <span style="font-size: 8pt;" class="fonte mb-2 black--text"
        >Minha Rede</span
      >
      <div class="expande-horizontal">
        <span class="fonte-bold black--text" :style="`font-size: 26px;`"
          >{{ sum }} Pessoas na rede</span
        >
        <v-spacer></v-spacer>
        <v-btn @click="$router.push('/unilevel')" icon color="grey">
          <v-icon size="30" color="grey"> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </div>

    <div class="expande-horizontal wrap mt-1">
      <v-btn depressed rounded outlined small light color="#444">
        <span class="text-capitalize"
          >{{ getUnilevels.level_1 ? getUnilevels.level_1.length : 0 }} de 6
          Diretos</span
        >
      </v-btn>
      <v-btn
        depressed
        rounded
        outlined
        small
        light
        color="#999"
        class="ma-1 mt-0"
      >
        <span class="text-lowercase" style="color: grey;"
          ><span class="fonte-bold" style="font-size: 10pt; color: #333;"
            >{{ getUnilevels.level_2 ? getUnilevels.level_2.length : 0 }} / 36
          </span>
          no 2º
        </span>
      </v-btn>
      <v-btn
        depressed
        rounded
        outlined
        small
        light
        color="#999"
        class="ma-1 mt-0"
      >
        <span class="text-lowercase" style="color: grey;"
          ><span class="fonte-bold" style="font-size: 10pt; color: #333;"
            >{{ getUnilevels.level_3 ? getUnilevels.level_3.length : 0 }} /
            216</span
          >
          no 3º
        </span>
      </v-btn>
      <v-btn
        depressed
        rounded
        outlined
        small
        light
        color="#999"
        class="ma-1 mt-0"
      >
        <span class="text-lowercase" style="color: grey;"
          ><span class="fonte-bold" style="font-size: 10pt; color: #333;"
            >{{ getUnilevels.level_4 ? getUnilevels.level_4.length : 0 }} /
            1296</span
          >
          no 4º
        </span>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getUnilevels"]),
    sum() {
      let soma = 0;
      if (this.getUnilevels.level_1 && this.getUnilevels.level_1.length) {
        soma += this.getUnilevels.level_1.length;
      }
      if (this.getUnilevels.level_2 && this.getUnilevels.level_2.length) {
        soma += this.getUnilevels.level_2.length;
      }
      if (this.getUnilevels.level_3 && this.getUnilevels.level_3.length) {
        soma += this.getUnilevels.level_3.length;
      }
      if (this.getUnilevels.level_4 && this.getUnilevels.level_4.length) {
        soma += this.getUnilevels.level_4.length;
      }
      return soma;
    }
  },
};
</script>
