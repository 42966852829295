<template>
  <v-card
    outlined
    color="#f2f2f2"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
      <span class="mb-3 fonte black--text" :style="`font-size: 12px;`">
        Meu Link de Indicação
      </span>

      <div style="width: 100%" class="expande-horizontal mb-2 pb-1 pt-0">
        <v-chip style="width: 100%;">
          <input
            id="foo"
            :value="myLink"
            class="fonte"
            readonly
            style="margin-left: -13px; color: #999; min-width: 600px !important; border: none !important; border-radius: 5px; padding: 5px; height: 40px; margin-right: 6px;"
          />
        </v-chip>
        <button
          class="btn1 ml-2"
          icon
          @click="
            createGlobalMessage({
              type: 'success',
              message: 'Link copiado com sucesso!',
              timeout: 3000
            })
          "
          data-clipboard-target="#foo"
          style="background-color: #c7c8c9;border-radius: 50%;min-width: 40px; min-height: 40px; max-width: 40px; max-height: 40px;"
        >
          <v-icon>mdi-content-copy</v-icon>
        </button>
      </div>
      <!-- <v-icon>mdi-cash-multiple</v-icon> -->
      <div class="expande-horizontal">
        <v-btn depressed rounded outlined small light color="#444">
          <span class="text-capitalize"> O Link está ativo</span>
        </v-btn>
      </div>
    </div>

  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClipboardJS from "clipboard";

export default {
  data() {
    return {
      myLink: ""
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    linkMount() {
      // this.myLink = `https://${process.env.VUE_APP_DOMAIN}/#/${this.getLoggedUser.nickname}`;
      this.myLink = `https://app.scnrevolution.com.br/#/${this.getLoggedUser.nickname}-000${this.getLoggedUser.id_user}`;
      new ClipboardJS(".btn1");
    }
  },
  mounted() {
    this.linkMount();
  }
};
</script>
