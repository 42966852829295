<template>
  <v-dialog
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '500'"
    v-model="modal"
    persistent
  >
    <v-flex xs12>
      <div class="expande-horizontal centraliza">
        <v-card
          dark
          color="#e5e5e5"
          class="expande-horizontal column pa-3"
          style="flex-wrap: wrap; border-radius: 12px;"
        >
          <span
            v-if="getLoggedUser.accession_paid"
            class="mb-3 fonte black--text"
            :style="`font-size: 12px;`"
          >
            Adesão paga
          </span>
          <span
            v-else
            class="mb-3 fonte black--text"
            :style="`font-size: 12px;`"
          >
            1º Passo: Adesão
          </span>
          <div
            v-if="getLoggedUser.accession_paid"
            :class="{
              'expande-horizontal wrap centraliza':
                $vuetify.breakpoint.smAndDown
            }"
          >
            <v-flex xs12>
              <div class="expande-horizontal centraliza">
                <v-avatar color="green">
                  <v-icon>mdi-check</v-icon>
                </v-avatar>
              </div>
            </v-flex>
            <v-flex class="px-1" xs12>
              <div class="expande-horizontal centraliza column">
                <span class="py-4 fonte-bold black--text" style="font-size: 24pt;">
                  Pagamento Recebido
                </span>
                <span
                  class="fonte grey--text mb-2 text-center"
                  style="font-size: 12pt;"
                >
                  Agora você já pode utilizar todas as ferramentas disponíveis
                  que preparamos pra você!
                </span>
                <v-btn
                  v-if="!getLoggedUser.accession_paid"
                  depressed
                  class="mb-2"
                  >Já Paguei</v-btn
                >
                <v-btn
                  v-if="getLoggedUser.accession_paid"
                  depressed
                  class="mb-2"
                  @click="modal = false"
                  >Vamos lá</v-btn
                >
              </div>
            </v-flex>
          </div>
          <div
            v-else
            :class="{
              'expande-horizontal wrap centraliza':
                $vuetify.breakpoint.smAndDown
            }"
          >
            <v-flex class="px-1" xs12>
              <div class="expande-horizontal centraliza column">
                <span class="fonte-bold black--text" style="font-size: 24pt;">
                  Boleto de adesão
                </span>
                <span class="fonte grey--text mb-2" style="font-size: 12pt;">
                  Visualize ou faça o download abaixo
                </span>
                <v-btn @click="verifyAccessionPaid" depressed class="mb-2"
                  >Já Paguei</v-btn
                >
              </div>
            </v-flex>
            <v-flex class="px-1" xs12>
              <div
                style="width: 100%"
                class="expande-horizontal column mb-2 pb-1 pt-0"
              >
                <iframe
                  height="500"
                  :src="
                    bankSlipPending && bankSlipPending.external_data
                      ? bankSlipPending.external_data.bankSlipUrl
                      : false
                  "
                  frameborder="0"
                ></iframe>
                <button
                  class="btn2 ml-1 mt-2"
                  icon
                  v-if="false"
                  @click="
                    createGlobalMessage({
                      type: 'success',
                      message: 'Número do boleto copiado com sucesso!',
                      timeout: 3000
                    })
                  "
                  data-clipboard-target="#foo"
                  style="background-color: #333;border-radius: 6px;min-width: 40px; min-height: 40px; max-height: 40px;"
                >
                  <span class="fonte-bold white--text">
                    Clique baixar o boleto
                  </span>
                  <v-icon color="white">mdi-download</v-icon>
                </button>
              </div>
            </v-flex>
          </div>
        </v-card>
      </div>
    </v-flex>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["verifyAccessionPaid"],
  data() {
    return {
      modal: false
    };
  },
  methods: {
    ...mapActions(["createGlobalMessage"])
  },
  computed: {
    ...mapGetters(["getLoggedUser", "getTransactions"]),
    bankSlipPending() {
      if (this.getLoggedUser.accession_paid) return { external_data: {} };
      let transaction = this.getTransactions.filter(t => t.account_activation);
      return transaction[0];
    }
  },
  created() {
    if (!this.getLoggedUser.accession_paid) {
      this.modal = true;
    }
  }
};
</script>

<style>
.personal-card {
  background: #795548; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #795548,
    #533b33
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #795548,
    #4f382f
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  animation: pulse-personal 2s infinite alternate;
}
.gold-card {
  background: #ffb347; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffcc33,
    #ffb347
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffcc33,
    #ffb347
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.platinum-card {
  background: #000428; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #004e92,
    #000428
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #004e92,
    #000428
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.black-card {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #383839,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #383839,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
@keyframes pulse-personal {
  0% {
    box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.587);
  }
  100% {
    box-shadow: 0 0 10px 0 rgba(236, 237, 238, 0.5); /* Sombra final mais forte e maior */
  }
}
.card-base {
  display: flex;
  min-width: 320px;
  max-width: 320px;
  min-height: 202px;
  max-height: 202px;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(253, 253, 253, 0.8);
  flex-direction: column;
  padding: 12px;
  position: relative;
}
.qr-code-scn {
  position: absolute;
  bottom: 4px;
  left: 10;
  border-radius: 12px;
  padding: 6px;
  padding-bottom: 0px;
  background: #fff;
}
.logo-card-scn {
  position: absolute;
  left: 14%;
  margin-top: -23px;
  opacity: 0.3;
}
.force-black-text {
  color: #333 !important;
}
</style>
