<template>
  <v-card
    v-if="documents_is_requireds"
    outlined
    color="#f2f2f2"
    class="expande-horizontal"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div
      class="expande-horizontal"
      style="flex-wrap: wrap; align-items: center; flex-direction: column;"
    >
      <v-list class="pa-0 ma-0" color="transparent" style="width: 100%;">
        <v-list-item
          v-if="!show_documents"
          @click="openDocumentRequireds"
          class="px-1"
        >
          <v-avatar class="mr-2">
            <v-icon size="30" class="" color="red"
              >mdi-card-account-details-outline</v-icon
            >
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="fonte-bold fonteMini" style="font-size: 16pt;"
                >Documentação</span
              >
            </v-list-item-title>
            <span class="fonte" style="font-size: 12pt;">
              Sua documentação precisa de ajustes
            </span>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon
                class="animate__animated animate__heartBeat animate__infinite"
                color="red"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-else class="px-1">
          <v-avatar class="mr-2">
            <v-icon size="30" class="" color="blue"
              >mdi-information-outline</v-icon
            >
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="fonte-bold fonteMini" style="font-size: 16pt;"
                >Explicação</span
              >
            </v-list-item-title>
            <span class="fonte" style="font-size: 12pt;">
              {{ document_approvation.motive }}
            </span>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="closeShowDocuments">
              <v-icon color="#333">mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-dialog v-model="show_cam" width="350">
        <v-card width="350" class="pa-2">
          <div class="expande-horizontal pa-3">
            <Camera
              ref="camera"
              v-model="selfie"
              :path="`documents/selfie/${getLoggedUser.cpf}`"
            />
          </div>
          <div class="expande-horizontal centraliza">
            <v-btn
              v-show="selfie"
              @click="saveSelfie"
              :loading="loading"
              x-large
              block
              color="#333"
            >
              <span class="white--text fonte-bold">Está boa</span>
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <div class="expande-horizontal pa-2 pb-0">
        <v-list-item
          style="background-color: #fff;"
          v-if="
            !selfie &&
              !show_cam &&
              show_documents &&
              !document_approvation.selfie_approvation
          "
          @click="startCamera"
          class="pl-3 pr-1"
        >
          <v-list-item-content>
            <v-list-item-title>
              <span class="fonte fonteMini" style="font-size: 12pt;"
                >Selfie</span
              >
            </v-list-item-title>
            <span class="fonte" style="font-size: 12pt;">
              Clique para refazer a selfie
            </span>
          </v-list-item-content>
          <v-list-item-action>
            <v-avatar color="#f2f2f2" class="mr-2">
              <v-icon class="" color="orange">mdi-camera-outline</v-icon>
            </v-avatar>
          </v-list-item-action>
        </v-list-item>
      </div>
      <document-files
        v-show="show_documents"
        v-model="documents"
        @input="documentsOk($event)"
        :items="documents_requireds"
        :basePath="`documents/user/${getLoggedUser.email}`"
      />
      <div
        v-if="show_documents && documents_ok && selfie_ok"
        class="expande-horizontal pa-2"
      >
        <v-btn @click="resend" :loading="loading" x-large block color="#333">
          <span class="white--text fonte-bold">Reenviar Documentos</span>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import DocumentFiles from "../../2#auth/components/DocumentFiles.vue";
import Camera from "../../2#auth/components/Camera.vue";

export default {
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  components: {
    DocumentFiles,
    Camera
  },
  data() {
    return {
      documents_is_requireds: false,
      show_documents: false,
      document_approvation: {
        selfie_approvation: false
      },
      documents: [],
      selfie: "",
      documents_requireds: [],
      selfie_ok: true,
      documents_ok: false,
      show_cam: false,
      loading: false
    };
  },
  methods: {
    saveSelfie() {
      this.document_approvation.selfie = this.selfie;
      this.show_cam = false;
      this.selfie_ok = true;
    },
    startCamera() {
      if (this.selfie_ok) return;
      this.show_cam = true;
      setTimeout(async () => {
        await this.$refs.camera.startCamera();
      }, 300);
    },
    documentsOk(docs) {
      if (docs.length == this.documents_requireds.length) {
        this.documents_ok = true;
      }
    },
    openDocumentRequireds() {
      this.show_documents = true;
    },
    closeShowDocuments() {
      try {
        this.show_documents = false;
      } catch (e) {
        console.log(e);
      }
    },
    fetchDocumentStatus() {
      this.$run("user/verify-document-approvation", {}, "user")
        .then(res => {
          this.document_approvation = res.data.document_approvation;
          if (this.document_approvation.status === "approved") return;
          if (this.document_approvation.status === "pending") return;
          if (this.document_approvation.selfie_approvation) {
            this.selfie_ok = true;
          } else {
            this.selfie_ok = false;
          }
          // this.documents = res.data.documents;[]
          console.log("res", res.data.documents);
          const docs = res.data.documents || [];
          console.log('documentos', docs);
          let docs_requireds = [];
          docs.map(doc => {
            if (!doc) return;
            if (doc.approved) return;
            docs_requireds.push({
              name: doc.name,
              required: doc.required
            });
          });
          console.log('documentos processados', docs_requireds)
          this.documents_requireds = docs_requireds;
          setTimeout(() => {
            this.documents_is_requireds = true;
          }, 1000);
        })
        .catch(e => {
          console.log("e", e);
        });
    },
    resend() {
      this.loading = true;
      this.$run(
        "user/resend-document-approvation",
        {
          document_approvation: this.document_approvation,
          documents: this.documents
        },
        "user"
      ).then(() => {
        this.loading = false;
        alert("Documentos reenviados, obrigado!");
        this.documents_is_requireds = false;
      });
    }
  },
  mounted() {
    this.fetchDocumentStatus();
  }
};
</script>

<style>
.document-status {
  background: #16a085; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f4d03f,
    #16a085
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f4d03f,
    #16a085
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
