<template>
  <layout :title="$route.name" :options="[]" :toolbar_off="true">
    <div class="expande-horizontal wrap">
      <v-flex xs12 class="pa-3 pb-0">
        <div class="expande-horizontal centraliza">
          <img
            class="img-logo"
            height="40"
            src="logo.jpeg"
            style="border-radius: 6px;"
          />
          <v-spacer></v-spacer>
          <!-- <div class="expande-horizontal column title-item">
            <span style="font-size: 16pt;" class="fonte-bold">SCN</span>
            <span class="fonte grey--text">Bem vindo(a)</span>
          </div> -->
          <div>
            <!-- <v-avatar
              @click="$router.push('/logged-user')"
              size="31"
              style="cursor: pointer;"
              color="#e5e5e5"
              icon
            >
              <v-icon size="21">mdi-account</v-icon>
            </v-avatar> -->
            <v-menu left offset-y rounded bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon v-if="!profile_image" color="black"
                    >mdi-account-circle-outline</v-icon
                  >
                  <v-avatar v-else>
                    <v-img :src="profile_image"></v-img>
                  </v-avatar>
                </v-btn>
              </template>

              <v-list style="min-width: 300px;" class="fonte px-0 mx-0" dense>
                <v-list-item class="mx-0 px-2" to="/logged-user">
                  <v-avatar
                    v-if="!profile_image"
                    size="35"
                    color="#e5e5e5"
                    class="mr-2"
                  >
                    <v-icon size="19">mdi-account-circle-outline</v-icon>
                  </v-avatar>
                  <v-avatar class="mr-2" size="35" v-else>
                    <v-img :src="profile_image"></v-img>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 11pt;"
                      class="fonte-bold"
                      >Meu perfil</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      getLoggedUser.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="mx-0 px-2" to="/config">
                  <v-avatar size="35" color="#e5e5e5" class="mr-2">
                    <v-icon size="19">mdi-cog-outline</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 11pt;"
                      class="fonte-bold"
                      >Configurações</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >Foto de perfil e senha.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="logout" class="mx-0 px-2">
                  <v-avatar size="35" color="#e5e5e5" class="mr-2">
                    <v-icon size="19">mdi-run</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 11pt;"
                      class="fonte-bold"
                      >Sair</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >desconecta a sua conta</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-flex>

      <Adesao :verifyAccessionPaid="verifyAccessionPaid" />
      <v-flex xs12 class="pa-3">
        <DocumentStatus class="cool-shaddow" />
      </v-flex>
      <v-flex xs12 class="pa-3">
        <Graduacao class="cool-shaddow" />
      </v-flex>
      
      <div class="expande-horizontal wrap">
        <v-flex xs12>
          <div class="expande-horizontal wrap pa-3 px-3">
            <v-slide-group :show-arrows="false">
              <v-slide-item>
                <div
                  @click="$router.push('/pagar/unilevel')"
                  class="centraliza column mr-4"
                  style="display: flex; width: 60px;"
                >
                  <div
                    class="centraliza"
                    style="border-radius: 12px; background-color: #fff; border: 1px solid #e1e0e0; display: flex; width: 60px; height: 60px;"
                  >
                    <v-icon size="30" class="cool-hover">mdi-account-key-outline</v-icon>
                  </div>
                  <span class="fonte fonteMini">Autenticar</span>
                </div>
              </v-slide-item>
              <v-slide-item>
                <div
                  @click="$router.push('/transferencia/unilevel')"
                  class="centraliza column mr-4"
                  style="display: flex; width: 60px;"
                >
                  <div
                    class="centraliza"
                    style="border-radius: 12px; background-color: #fff; border: 1px solid #e1e0e0; display: flex; width: 60px; height: 60px;"
                  >
                    <v-icon size="30" class="cool-hover">mdi-cash-fast</v-icon>
                  </div>
                  <span class="fonte fonteMini">Transferência</span>
                </div>
              </v-slide-item>
              <v-slide-item>
                <div
                  @click="$router.push('/transactions')"
                  class="centraliza column mr-4"
                  style="display: flex; width: 60px;"
                >
                  <div
                    class="centraliza"
                    style="border-radius: 12px; background-color: #fff; border: 1px solid #e1e0e0; display: flex; width: 60px; height: 60px;"
                  >
                    <v-icon size="30" class="cool-hover">mdi-receipt-text-outline</v-icon>
                  </div>
                  <span class="fonte fonteMini">Extratos</span>
                </div>
              </v-slide-item>
              <v-slide-item>
                <div
                  @click="$router.push('/deposito/unilevel')"
                  class="centraliza column mr-4"
                  style="display: flex; width: 60px;"
                >
                  <div
                    class="centraliza"
                    style="border-radius: 12px; background-color: #fff; border: 1px solid #e1e0e0; display: flex; width: 60px; height: 60px;"
                  >
                    <v-icon size="30" class="cool-hover">mdi-cash-plus</v-icon>
                  </div>
                  <span class="fonte fonteMini">Depósito</span>
                </div>
              </v-slide-item>
              <v-slide-item>
                <div
                  @click="$router.push('/estabelecimentos')"
                  class="centraliza column mr-1"
                  style="display: flex; width: 60px;"
                >
                  <div
                    class="centraliza"
                    style="border-radius: 12px; background-color: #fff; border: 1px solid #e1e0e0; display: flex; width: 60px; height: 60px;"
                  >
                    <v-icon size="30" class="cool-hover">mdi-storefront</v-icon>
                  </div>
                  <span class="fonte fonteMini">Empresas</span>
                </div>
              </v-slide-item>
            </v-slide-group>

            <!-- <div
              @click="$router.push('/mapa-empresas')"
              class="centraliza column mr-1 ml-3"
              style="display: flex; width: 60px; cursor: pointer;"
            >
              <div
                class="centraliza"
                style="border-radius: 12px; background-color: #f2f2f2; border: 1px solid #e1e0e0; display: flex; width: 60px; height: 60px;"
              >
                <v-icon size="30">mdi-map</v-icon>
              </div>
              <span class="fonte-bold">Mapa</span>
            </div> -->
            <!-- <div @click="$router.push('/solicitar-pagamento/unilevel')" class="centraliza column mr-3" style="display: flex; width: 60px;">
              <div
                class="centraliza"
                style="border-radius: 12px; background-color: #f2f2f2; border: 1px solid #e1e0e0; display: flex; width: 60px; height: 60px;"
              >
                <v-icon size="30">mdi-cash-register</v-icon>
              </div>
              <span class="fonte-bold">Receber</span>
            </div> -->
          </div>
        </v-flex>
        <v-flex xs12 class="pa-3">
          <CriarContaDigital class="cool-shaddow" />
        </v-flex>
        <v-flex
          v-if="show_unilevel"
          xs12
          :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
        >
          <SearchStablishment class="cool-shaddow" />
        </v-flex>
        <v-flex
          v-if="show_unilevel"
          xs12
          md6
          :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
        >
          <SaldoDisponivel class="cool-shaddow"  />
        </v-flex>
        <v-flex
          v-if="show_unilevel"
          xs12
          md6
          :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
        >
          <SaldoContabil class="cool-shaddow" />
        </v-flex>
        <v-flex
          v-if="show_unilevel"
          xs12
          md6
          :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
        >
          <Unilevel class="cool-shaddow" />
        </v-flex>
        <v-flex
          v-if="getLoggedUser.accession_paid"
          xs12
          md6
          :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
        >
          <Link class="cool-shaddow" />
        </v-flex>
        <v-flex
          v-if="show_unilevel && getLoggedUser.accession_paid"
          xs12
          md6
          :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
        >
          <QtdEmpresas class="cool-shaddow" />
        </v-flex>
        <v-flex
          v-if="show_unilevel"
          xs12
          md6
          :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
        >
          <LinkEmpresarial class="cool-shaddow" />
        </v-flex>
      </div>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Saldo from "./components/Saldo";
import SaldoDisponivel from "./components/SaldoDisponivel";
import SaldoContabil from "./components/SaldoContabil";
import PlanoDeCarreira from "./components/PlanoDeCarreira";
import Tokens from "./components/Tokens";
import Unilevel from "./components/Unilevel";
import Notificacoes from "./components/Notificacoes";
import ChartSaldo from "./components/ChartSaldo";
import Link from "./components/Link";
import PlanExpiresIn from "./components/PlanExpiresIn";
import Graduacao from "./components/Graduacao";
import Adesao from "./components/Adesao";
import TotalGain from "./components/TotalGain";
import LinkEmpresarial from "./components/LinkEmpresarial";
import QtdEmpresas from "./components/QtdEmpresas";
import SearchStablishment from "./components/SearchStablishment";
import CriarContaDigital from "./components/CriarContaDigital";
import DocumentStatus from "./components/DocumentStatus";

export default {
  data() {
    return {
      // show_unilevel: process.env.VUE_APP_LOJA_UNILEVEL,
      show_unilevel: true,
      show_token: process.env.VUE_APP_LOJA_TOKEN,
      show_ativos: process.env.VUE_APP_LOJA_ATIVOS,
      modal_unread: false,
      total_unreads: 0,
      timeout_accession: null
    };
  },
  components: {
    Graduacao,
    Saldo,
    CriarContaDigital,
    SaldoDisponivel,
    SaldoContabil,
    PlanoDeCarreira,
    Tokens,
    Unilevel,
    Notificacoes,
    ChartSaldo,
    Link,
    PlanExpiresIn,
    TotalGain,
    LinkEmpresarial,
    QtdEmpresas,
    Adesao,
    SearchStablishment,
    DocumentStatus
  },
  computed: {
    ...mapGetters([
      "getDashboard",
      "getLoggedUser",
      "getPurchases",
      "get_notifications",
      "getBalanceUnilevel"
    ]),
    profile_image() {
      const user = JSON.parse(localStorage.getItem("user"));
      const img_profile = user ? user.profile_image : false;
      return user ? img_profile : this.getLoggedUser.profile_image || "";
    },
    firstName() {
      if (this.getLoggedUser) {
        return this.getLoggedUser.name.split(" ")[0];
      }
      return "";
    }
  },
  methods: {
    ...mapActions([
      "listTransactions",
      "listBalanceInvestment",
      "listBalanceToken",
      "listBalanceUnilevel",
      "list_notifications",
      "listPurchases",
      "listUnilevels",
      "logout"
    ]),
    verifyAccessionPaid() {
      const self = this;
      if (!this.getLoggedUser.accession_paid) {
        this.$run("user/verify-accession-paid", null, "user").then(res => {
          if (res.data.accession_paid) {
            let user = JSON.parse(localStorage.getItem("user"));
            user.accession_paid = res.data.accession_paid;
            localStorage.setItem("user", JSON.stringify(user));
            this.$store.commit("setLoggedUser", user);

            this.cleanTimeout(this.timeout_accession);
          } else {
            this.timeout_accession = setTimeout(() => {
              self.verifyAccessionPaid();
            }, 6000);
          }
        });
      }
    }
  },
  mounted() {
    this.verifyAccessionPaid();
  },
  created() {
    // this.listBalanceInvestment();
    // this.listBalanceToken();
    this.listBalanceUnilevel();
    this.listUnilevels();
    this.listTransactions();
    this.listPurchases();
    // this.list_notifications();
  }
};
</script>

<style>
.card-graduation {
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #414345,
    #232526
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.img-logo {
  filter: invert(97%);
}
.cool-hover {
  transition: 0.3s;
  cursor: pointer;
}
.cool-hover:hover {
  transform: scale(1.1);
}
</style>
